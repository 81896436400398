import './App.css';
import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import {AnimatePresence} from "framer-motion";
import {MainPage} from "./pages/MainPage";


function App() {
    const location = useLocation();
    return (
        <AnimatePresence mode={"wait"}>
            <Routes key={location.pathname} location={location}>
                <Route path="/" element={<MainPage />}/>
                <Route path="*"
                       element={<Navigate to={'/'}/> }/>
            </Routes>
        </AnimatePresence>
    );
}

export default App;
