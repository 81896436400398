import {Outlet} from "react-router-dom";
import AnimatedPage from "../components/AnimatedPage";
import AnimatedPage2 from "../components/AnimatedPage2";
import AnimatedPage3 from "../components/AnimatedPage3";

export function MainPage() {
    return (
        <>
            <div style={{
                margin: 0,
                padding: 0,
                position: "absolute",
                top: "50%", right: "50%",
                transform: "translate(50%,-50%)"
            }}>
                <AnimatedPage2>
                    <h1 className={"t1"}
                        style={{
                        marginTop: 0, paddingTop: 0,
                        marginBottom: 0, paddingBottom: 0}}>
                        ENGINEER & DESIGN
                    </h1>
                </AnimatedPage2>

                <AnimatedPage3>
                    <h1 className={"t1"}
                        style={{
                        marginTop: 5, paddingTop: 5,
                        marginBottom: 0, paddingBottom: 0}} >
                        SCALABLE ENTERPRISE SYSTEMS
                    </h1>
                </AnimatedPage3>

            <AnimatedPage>
                <h1 className={"l1"} style={{
                    fontFamily: "'Volkhov', serif",
                    marginTop: 0}} >
                    Groovy.Systems
                </h1>
            </AnimatedPage>

            </div>
            <Outlet/>
        </>
    )
}